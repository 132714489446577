import React from 'react'
import SEO from '~/components/seo'
import {
  Wrapper,
  Desc,
  Title,
  Container,
  Head,
  ListItem,
  List,
} from '../styles/staticPage'

const ShoppingGuidePage = () => (
  <Wrapper>
    <SEO title="ショッピングガイド" />
    <Head>ショッピングガイド</Head>
    <Container>
      <Title>お支払方法について</Title>
      <Desc>お支払い方法については下記の方法をお選びいただけます。</Desc>
    </Container>

    <Container>
      <Title>クレジットカード決済</Title>
      <Desc>
        商品の準備が整い次第、発送いたします。
        <br />
        ※ご注文を頂いたタイミングで決済いたしますので、お使いのカード会社の締め日により商品の発送前にご請求が届いてしまう場合がございます。
        予めご了承下さい。
        <br />
        取扱カード：VISA,MASTER,AMEX <br />
        クレジットカード決済はPayPalを経由した決済が可能です。
      </Desc>
    </Container>

    <Container>
      <Title>銀行振り込み</Title>
      <Desc>
        ご注文後、７営業日以内にご入金をお願い致します。
        ご入金確認後、発送作業に取り掛からせて頂きます。
        なお、お振込の手数料はお客さまにてご負担頂きますようお願い申し上げます。
      </Desc>
    </Container>

    <Container>
      <Title>お振込先</Title>
      <Desc>
        ゆうちょ銀行 <br />
        記号:17450 <br />
        番号:24291001 <br />
        店名:七四八 <br />
        店番:748 <br />
        番号:2429100 <br />
        普通 <br />
        タカタカズキ
      </Desc>
    </Container>

    <Container>
      <Title>配送方法・送料について</Title>
      <Title>配送業者について</Title>
      <Desc>
        当店のお取り扱い商品は「ゆうパック」にて配送致します。
        その他の配送業者は選択できませんのでご了承ください。
      </Desc>
    </Container>

    <Container>
      <Title>送料について</Title>
      <List>
        <ListItem>送料は全国一律 ￥1,000(税込)です。</ListItem>
        <ListItem>
          ご注文商品の合計金額 が9,999（税込）以上
          の場合、送料は無料でございます。※手数料は含まれません。
        </ListItem>
      </List>
    </Container>

    <Container>
      <Title>発送日について</Title>
      <Desc>
        お客様からのご入金・決済を確認次第、発送作業に取り掛からせて頂きます。
        <br />
        ※できるだけご希望納期に間に合うよう努めますが、在庫状況、天候・自然災害・交通事情の影響、年末年始などの繁忙期は、配送が遅れる場合があります。余裕をもった日程でご注文下さいますよう予めお願いいたします。
        <br />
        ※配送業者や弊社の梱包・発送作業の都合により、ご注文商品が希望配達時間帯にお届けできない場合があります。あらかじめご了承ください。
      </Desc>
    </Container>

    <Container>
      <Title>返品交換について</Title>
      <Title>返品・交換のご対応について</Title>
      <Desc>
        商品発送後の返品・返却等はお受けいたしかねます。
        商品が不良の場合のみ交換いたしますので、当店へご連絡頂き、速やかに返送頂きますようお願い致します。
        尚、お客様のご都合による返品には一切応じかねますのでご了承ください。
        ブレスレットの修理については、修理費＋送料をいただいております。費用については個別に異なりますのでご相談ください。
      </Desc>
    </Container>

    <Container>
      <Title>返品期限について</Title>
      <Desc>
        商品出荷より７日以内にご連絡下さい。７日以上経過した返品の場合には原則としてお受け致しません。
      </Desc>
    </Container>

    <Container>
      <Title>返品時の送料について</Title>
      <Desc>
        不良品の場合は弊社が負担いたします。
        それ以外はお客様のご負担となりますのでご了承ください。
      </Desc>
    </Container>
  </Wrapper>
)

export default ShoppingGuidePage
